import { Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useEffect } from "react";

export default function Layout() {
  const location = useLocation();

  useEffect(() => {
    if (!localStorage?.getItem("access_token")) {
      localStorage.clear();
      // Using window.location instead of <Navigate> for redirection in useEffect
      window.location.href = '/login';
    }
  }, []);

  // Define paths where the sidebar should be hidden
  const hiddenSidebarPaths = ['/login', '/signup', '/pos']; // Add more paths as needed

  // Determine if the sidebar should be hidden
  const isSidebarHidden = hiddenSidebarPaths.includes(location.pathname);

  return (
    <div className="flex flex-col">
      <Header />
      {/* Conditionally render Sidebar based on current path */}
      {!isSidebarHidden && <Sidebar />}
      <div className={`flex ${isSidebarHidden ? 'w-full' : 'flex-1'}`}>
        <div className={`min-h-screen w-full flex-col bg-muted/40 ${isSidebarHidden ? '' : 'flex sm:pl-60'}`}>
          <div className="flex flex-col sm:gap-4 sm:py-4">
            <main className="flex flex-1 flex-col gap-4 md:gap-8 md:px-6 p-4">
              <Outlet />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}
import { configureStore } from "@reduxjs/toolkit";
import mediaSlice from "./slice/mediaSlice";
import basketSlice from "./slice/basketSlice";

export const store = configureStore({
  reducer: {
    media: mediaSlice,
    basket: basketSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

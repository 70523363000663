import {
  Home,
  LineChart,
  Package,
  ShoppingCart,
  Users,
  Blocks,
  TicketPercent,
  MicVocal,
  Settings,
  ChevronUp,
  FilePen,
  Palette,
} from "lucide-react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import Logo from "@/assets/images/pinecart.svg";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "@/components/ui/menubar";
import React, { useEffect, useState } from "react";
import { getOrderCount } from "@/services/orderService";
import { Badge } from "../ui/badge";
import { getStoreDetails } from "@/services/storeServices";
// import { getDomains } from "@/services/domainService";
// import { ExternalLink } from "lucide-react";

// type primaryDomainType = {
//   url: string;
//   type: string;
// };

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPOSEnabled, setIsPOSEnabled] = useState(false);
  // const [primaryDomain, setPrimaryDomain] = useState<primaryDomainType>({
  //   url: "",
  //   type: ""
  // });

  const first_name = localStorage.getItem("first_name");

  const [orderCount, setOrderCount] = useState(0);

  useEffect(() => {
    // getDomains().then((res:any) => {
    //   const primary = res?.data?.domain?.find((i: any) => i?.is_primary);
    //   setPrimaryDomain(primary);
    // });
    getStoreDetails()
    .then(res => setIsPOSEnabled(res?.data?.data?.modules[0]?.status))
    .catch((err) => console.log(err));
    getOrderCount()
      .then((res) => setOrderCount(res.data.order_count))
      .catch((err) => console.log(err));
  }, []);

  return (
    <aside className="fixed inset-y-0 left-0 z-10 hidden w-60 flex-col border-r bg-white sm:flex">
      <div className="flex h-full max-h-screen flex-col gap-2">
        <div className="flex h-14 items-center border-b px-4 lg:h-[60px] lg:px-6">
          <NavLink to="/" className="flex items-center gap-2 font-semibold">
            <img src={Logo} className=" w-full h-[24px] object-contain" />
          </NavLink>
        </div>
        <div className="flex-1">
          <nav className="grid items-start px-2 text-sm font-medium lg:px-4">
            {!isPOSEnabled ?
            sideMenu.map((item, i) => {
              const Icon: any = item?.icon;
              return (
                <React.Fragment key={i}>
                  <NavLink
                    to={item?.route}
                    className={({ isActive }) =>
                      `flex items-center justify-between rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary ${
                        isActive
                          ? " bg-gray-200 hover:bg-gray-200"
                          : "text-black hover:bg-gray-100"
                      }`
                    }
                  >
                    <div className="flex items-center gap-3">
                      <Icon className="h-4 w-4" />
                      {item?.name}
                    </div>
                    {item.name == "Orders" && (
                      <Badge
                        variant="secondary"
                        className="border border-gray-300"
                      >
                        {orderCount}
                      </Badge>
                    )}
                  </NavLink>
                  <div className=" ml-2">
                    {(location.pathname === item.route ||
                      item.nested?.some((e) => e.route == location.pathname)) &&
                      item.nested?.map((el, i) => {
                        // const Icn = el?.icon;
                        return (
                          <NavLink
                            key={i}
                            to={el?.route}
                            className={({ isActive }) =>
                              `flex items-center gap-3 rounded-lg px-8 py-2 text-muted-foreground text-gray-500 transition-all hover:text-primary ${
                                isActive
                                  ? " bg-gray-200 text-gray-600 hover:bg-gray-200"
                                  : "hover:bg-gray-100"
                              }`
                            }
                          >
                            {el?.name}
                          </NavLink>
                        );
                      })}
                  </div>
                </React.Fragment>
              );
            }) :
            POSSideBar.map((item, i) => {
              const Icon: any = item?.icon;
              return (
                <React.Fragment key={i}>
                  <NavLink
                    to={item?.route}
                    className={({ isActive }) =>
                      `flex items-center justify-between rounded-lg px-3 py-2 text-muted-foreground transition-all hover:text-primary ${
                        isActive
                          ? " bg-gray-200 hover:bg-gray-200"
                          : "text-black hover:bg-gray-100"
                      }`
                    }
                  >
                    <div className="flex items-center gap-3">
                      <Icon className="h-4 w-4" />
                      {item?.name}
                    </div>
                    {!isPOSEnabled && item.name == "Orders" && (
                      <Badge
                        variant="secondary"
                        className="border border-gray-300"
                      >
                        {orderCount}
                      </Badge>
                    )}
                  </NavLink>
                  <div className=" ml-2">
                    {(location.pathname === item.route ||
                      item.nested?.some((e) => e.route == location.pathname)) &&
                      item.nested?.map((el, i) => {
                        // const Icn = el?.icon;
                        return (
                          <NavLink
                            key={i}
                            to={el?.route}
                            className={({ isActive }) =>
                              `flex items-center gap-3 rounded-lg px-8 py-2 text-muted-foreground text-gray-500 transition-all hover:text-primary ${
                                isActive
                                  ? " bg-gray-200 text-gray-600 hover:bg-gray-200"
                                  : "hover:bg-gray-100"
                              }`
                            }
                          >
                            {el?.name}
                          </NavLink>
                        );
                      })}
                  </div>
                </React.Fragment>
              );
            })
            }
          </nav>
        </div>
        <div className="flex-1"></div>
        <div className="mt-auto p-4">
          {false && (
            <Card x-chunk="dashboard-02-chunk-0">
              <CardHeader className="p-2 pt-0 md:p-4">
                <CardTitle>Upgrade Subscription</CardTitle>
                <CardDescription>
                  Your account is on 7-day free trial. Unlock features by
                  upgrading the plans.
                </CardDescription>
              </CardHeader>
              <CardContent className="p-2 pt-0 md:p-4 md:pt-0">
                <NavLink to="/plans">
                  <Button size="sm" className="w-full">
                    Upgrade
                  </Button>
                </NavLink>
              </CardContent>
            </Card>
          )}
          <div className="flex flex-row gap-2 items-center mt-8 justify-between">
            <div className="flex flex-row gap-2 items-center">
              <Avatar>
                <AvatarImage
                  src="https://github.com/shadcn.png"
                  alt="pinecart"
                />
                <AvatarFallback>CN</AvatarFallback>
              </Avatar>
                <p className="font-semibold capitalize">{first_name}</p>
              <div>
                {/* <NavLink
                  to={primaryDomain?.type === 'default' ? `https://${primaryDomain?.url}.pinecart.in` : `https://${primaryDomain?.url}`}
                  target="_blank"
                  className="text-[12px] text-gray-400 leading-4 flex items-center gap-1 hover:text-green-900 underline"
                >
                  Visit Website
                  <ExternalLink size={10} className="text-green-900" />
                </NavLink> */}
                <div>
                </div>
              </div>
            </div>

            <Menubar>
              <MenubarMenu>
                <MenubarTrigger className="cursor-pointer w-6 h-auto p-0 flex items-center justify-center">
                  <ChevronUp size={20} className="p-0" />
                </MenubarTrigger>
                <MenubarContent>
                  <MenubarItem
                    onClick={() => {
                      localStorage.clear();
                      navigate("/login");
                    }}
                  >
                    Logout
                  </MenubarItem>
                  {/* <MenubarItem>View profile</MenubarItem> */}
                  <MenubarItem
                    onClick={() => {
                      navigate("/select-store");
                    }}
                  >
                    Switch store
                  </MenubarItem>
                </MenubarContent>
              </MenubarMenu>
            </Menubar>
          </div>
        </div>
      </div>
    </aside>
  );
}

const sideMenu = [
  {
    name: "Home",
    icon: Home,
    route: "/",
  },
  {
    name: "Orders",
    icon: ShoppingCart,
    route: "/orders",
  },
  {
    name: "Products",
    icon: Package,
    route: "/products",
    nested: [
      {
        name: "Categories",
        // icon: Layers3,
        route: "/category",
      },
    ],
  },
  {
    name: "Customers",
    icon: Users,
    route: "/customers",
  },
  {
    name: "Content",
    icon: FilePen,
    route: "/content",
    nested: [
      {
        name: "Pages",
        route: "/pages",
      },
      {
        name: "Media",
        route: "/media",
      },
      {
        name: "Blogs",
        route: "/blog",
      },
    ],
  },
  {
    name: "Analytics",
    icon: LineChart,
    route: "/analytics",
  },
  {
    name: "Marketing",
    icon: MicVocal,
    route: "/marketing",
    nested: [
      {
        name: "Abandoned Cart",
        route: "/abandoned-cart",
      },
      {
        name: "Feedback",
        route: "/feedback",
      },
    ],
  },
  {
    name: "Discounts",
    icon: TicketPercent,
    route: "/discount",
  },
  {
    name: "Integrations",
    icon: Blocks,
    route: "/integrations",
  },
  {
    name: "Customise",
    icon: Palette,
    route: "/customise",
    nested: [
      {
        name: "Menu",
        route: "/menu",
      }
    ],
  },
  {
    name: "Settings",
    icon: Settings,
    route: "/settings",
  },
];

const POSSideBar = [
  {
    name: "Home",
    icon: Home,
    route: "/",
  },
  {
    name: "Orders",
    icon: ShoppingCart,
    route: "/orders",
    nested: [
      {
        name: "POS",
        route: "/pos",
      },
    ],
  },
  {
    name: "Products",
    icon: Package,
    route: "/products",
    nested: [
      {
        name: "Categories",
        // icon: Layers3,
        route: "/category",
      },
    ],
  },
  {
    name: "Customers",
    icon: Users,
    route: "/customers",
  },
  {
    name: "Content",
    icon: FilePen,
    route: "/content",
    nested: [
      {
        name: "Media",
        route: "/media",
      }
    ],
  },
  {
    name: "Analytics",
    icon: LineChart,
    route: "/analytics",
  },
  {
    name: "Discounts",
    icon: TicketPercent,
    route: "/discount",
  },
  {
    name: "Settings",
    icon: Settings,
    route: "/settings",
  },
]

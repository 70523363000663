// import { getFolderData } from "@/services/mediaServices";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  assets: [],
  folders: [],
};

export const mediaAsyncThunk = createAsyncThunk("media/api", async () => {
  // const store_id = localStorage.getItem('storeId')
  // const { data } = await getFolderData(store_id);
  return [];
});

const counterSlice = createSlice({
  name: "media",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(mediaAsyncThunk.fulfilled, () => {
      // state.assets = payload.assets;
      // state.folders = payload.folders;
    });
    builder.addCase(mediaAsyncThunk.rejected, (state, action) => {
      console.log("----- error -----", { state, action });
    });
  },
});

export default counterSlice.reducer;

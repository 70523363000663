import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define the shape of a single basket item
interface BasketItem {
  _id: string;
  name: string;
  price: number;
  quantity: number;
  items: number;
  discount_price: number;
  tax_percentage: number;
  hsn: number;
  sku: String;
  taxAmount: number;
}

interface BasketState {
  basketItems: BasketItem[];
}

const initialState: BasketState = {
  basketItems: [],
};

export const basketSlice = createSlice({
  name: "Basket",
  initialState,
  reducers: {
    addItemToBasket: (state, action: PayloadAction<BasketItem>) => {
      const product = action.payload;

      const itemPrice = product?.discount_price || product?.price;
      const taxAmount = Number(
        (
          itemPrice -
          itemPrice / (1 + (product?.tax_percentage || 0) / 100)
        ).toFixed(2)
      );

      if (state.basketItems.find((i) => i._id === product?._id)) {
        const index = state.basketItems.findIndex((i) => i._id === product._id);

        state.basketItems[index].quantity++;
        // state.basketItems[index].taxAmount += taxAmount;
      } else {
        const updatedProduct = {...product, quantity:1, taxAmount}
        // product.quantity = 1;
        // product.taxAmount = taxAmount;
        state.basketItems.push(updatedProduct);
      }
    },
    IncreaseQuantity: (state, action) => {
      const increaseData = action.payload;
      const index = state.basketItems.findIndex(
        (i) => i._id === increaseData._id
      );
      state.basketItems[index].quantity++;
    },
    DecreaseQuantity: (state, action) => {
      const decreaseData = action.payload;
      const index = state.basketItems.findIndex(
        (i) => i._id === decreaseData._id
      );
      if (state.basketItems[index].quantity > 1) {
        state.basketItems[index].quantity--;
      }
    },
    RemoveItemFromBasket: (state, action) => {
      const removeItem = action.payload;
      state.basketItems = state.basketItems.filter(
        (item) => item._id !== removeItem?._id
      );
    },
    // New reducer to clear the basket
    clearBasket: (state) => {
      state.basketItems = [];
    },
  },
});

export const selectBasketTotal = (state: { basket: BasketState }) => {
  const discountSummary = state?.basket?.basketItems.reduce(
    (acc, { discount_price, price, taxAmount, quantity }) => {
      const itemPrice = (discount_price || price) * quantity;

      const totalTaxAmount = Number((taxAmount * quantity).toFixed(2));

      acc.subtotal += itemPrice;
      acc.tax += totalTaxAmount;

      return acc;
    },
    {
      subtotal: 0,
      tax: 0,
    }
  );

  return discountSummary;
};

export const {
  addItemToBasket,
  IncreaseQuantity,
  DecreaseQuantity,
  RemoveItemFromBasket,
  clearBasket,
} = basketSlice.actions;

export default basketSlice.reducer;

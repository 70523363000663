import { Suspense, lazy } from "react";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import "./App.css";
import Layout from "./components/layout/Layout";
import { GoogleOAuthProvider } from "@react-oauth/google";
import './fonts.css';

// Dynamic Imports
const Login = lazy(() => import("./modules/Authentication/pages/Login"));
const Product = lazy(() => import("./modules/Products/pages/Product"));
const Orders = lazy(() => import("./modules/Orders/pages/Orders"));
const Customers = lazy(() => import("./modules/Customers/pages/Customers"));
const Category = lazy(() => import("./modules/Categories/pages/Category"));
const RegisterForm = lazy(() => import("./modules/Authentication/pages/Register"));
const CreateStore = lazy(() => import("./modules/Store/pages/CreateStore"));
const SelectStore = lazy(() => import("./modules/Store/pages/SelectStore"));
const StoreSettings = lazy(() => import("./modules/Settings/pages/StoreSettings"));
const ProductDetails = lazy(() => import("./modules/Products/pages/ProductDetails"));
const CustomerDetails = lazy(() => import("./modules/Customers/pages/CustomerDetails"));
const AddCustomer = lazy(() => import("./modules/Customers/components/AddCustomer"));
const CategoryForm = lazy(() => import("./modules/Categories/pages/CategoryForm"));
const Integrations = lazy(() => import("./modules/Integrations/pages/Integrations"));
const OrderDetailsPage = lazy(() => import("./modules/Orders/pages/OrderDetailsPage"));
const Plans = lazy(() => import("./modules/Billing/pages/Plans"));
const Feedback = lazy(() => import("./modules/Marketing/Feedback/Feedback"));
const StoreSettingLayout = lazy(() => import("./components/layout/StoreSettingLayout"));
const Blog = lazy(() => import("./modules/Content/Blog/page/Blog"));
const BlogForm = lazy(() => import("./modules/Content/Blog/page/BlogForm"));
const ComingSoon = lazy(() => import("./components/common/ComingSoon"));
const Page = lazy(() => import("./modules/Content/Page/page/Page"));
const PageForm = lazy(() => import("./modules/Content/Page/page/PageForm"));
const StoreMeta = lazy(() => import("./modules/Store/StoreMeta"));
const Home = lazy(() => import("./modules/Home/pages/Home"));
const Analytics = lazy(() => import("./modules/Analytics/pages/Analytics"));
const Discount = lazy(() => import("./modules/Discount/pages/Discount"));
const DiscountForm = lazy(() => import("./modules/Discount/pages/DiscountForm"));
const CustomDomain = lazy(() => import("./modules/Settings/pages/CustomDomain"));
// const OrderDetailsPage = lazy(() => import("./pages/Order/OrderDetails"));
// const UserProfile = lazy(() => import("./modules/User/pages/UserProfile"));
const Menu = lazy(() => import("./modules/Appearance/Menu/pages/Menu"));
const CreateMenu = lazy(() => import("./modules/Appearance/Menu/components/CreateMenu"));
const Media = lazy(() => import("./modules/Content/Media/pages/Media"));
const Customise = lazy(() => import("./modules/Appearance/Customise/components/Customise"));
const ShippingSetting = lazy(() => import("./modules/Settings/pages/ShippingSetting"));
const Warehouse = lazy(() => import("./modules/Settings/Warehouse/pages/Warehouse"));
const WarehouseForm = lazy(() => import("./modules/Settings/Warehouse/pages/WarehouseForm"));
const UserTable = lazy(() => import("./modules/Settings/Users/components/UserTable"));
const UserForm = lazy(() => import("./modules/Settings/Users/pages/UserForm"));
const Content = lazy(() => import("./modules/Content/Content"));
const Marketing = lazy(() => import("./modules/Marketing/Marketing"));
const AbandonedCart = lazy(() => import("./modules/Marketing/AbandonedCart/pages/AbandonedCart"));
const POSOrderDetail = lazy(() => import("./modules/Orders/pages/POSOrderDetail"));
const POS = lazy(() => import("./modules/POS/pages/pos"));

function App() {
  return (
    <div className="h-full">
      <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
      <Suspense fallback={<div className="w-screen h-screen justify-center items-center"></div>}>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<RegisterForm />} />
          </Route>
          <Route element={<PrivateRoute />}>
            <Route path="/create-store" element={<CreateStore />} />
            <Route path="/select-store" element={<SelectStore />} />
            <Route element={<Layout />}>
              <Route path="" element={<Home />} />
              <Route path="/analytics" element={<Analytics />} />

              {/* Order Routes */}
              <Route path="/orders" element={<Orders />} />
              <Route path="/pos" element={<POS />} />
              <Route path="/pos-order/:id" element={<POSOrderDetail />} />
              <Route path="/orders/:id" element={<OrderDetailsPage />} />

              {/* Product Routes */}
              <Route path="products" element={<Product />} />
              <Route path="products/add" element={<ProductDetails />} />
              <Route path="products/edit" element={<ProductDetails />} />

              {/* Category Routes */}
              <Route path="category" element={<Category />} />
              <Route path="category/add" element={<CategoryForm />} />
              <Route path="category/edit" element={<CategoryForm />} />

              {/* Customer Routes */}
              <Route path="customers" element={<Customers />} />
              <Route path="customers/add" element={<AddCustomer />} />
              <Route
                path="customers/detail/:id"
                element={<CustomerDetails />}
              />

              {/* Integration Routes */}
              <Route path="/integrations" element={<Integrations />} />

              {/* Marketing Routes */}
              <Route path="/marketing" element={<Marketing />} />
              <Route path="abandoned-cart" element={<AbandonedCart />}/>
              <Route path="/plans" element={<Plans />} />
              <Route path="/feedback" element={<Feedback />} />

              {/* Content Routes */}
              <Route path="/content" element={<Content />} />

              {/* Blog Routes */}
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/create" element={<BlogForm />} />
              <Route path="/blog/edit" element={<BlogForm />} />

              {/* Media Routes */}
              <Route path="/media" element={<Media />} />

              {/* Pages Routes */}
              <Route path="/pages" element={<Page />} />
              <Route path="/page/create" element={<PageForm />} />
              <Route path="/page/edit" element={<PageForm />} />

              {/* Discount Routes */}
              <Route path="/discount" element={<Discount />} />
              <Route path="discount/edit" element={<DiscountForm />} />
              <Route path="discount/add" element={<DiscountForm />} />

              {/* Setting Routes */}
              <Route path="/settings" element={<StoreSettingLayout />}>
                <Route path="" element={<StoreSettings />} />
                <Route path="payment" element={<h1>Page 3</h1>} />
                <Route path="shipping" element={<ShippingSetting />} />
                <Route path="meta" element={<StoreMeta />} />
                <Route path="custom-domain" element={<CustomDomain />} />
                <Route path="warehouse" element={<Warehouse />} />
                <Route path="warehouse/add" element={<WarehouseForm />} />
                <Route path="warehouse/edit" element={<WarehouseForm />} />
                <Route path="user-management" element={<UserTable />} />
                <Route path="user-management/add" element={<UserForm />} />
                <Route path="user-management/edit" element={<UserForm />} />
              </Route>

              {/* Access Management Routes */}
              {/* <Route path="profile" element={<UserProfile />} /> */}

              {/* Customise Routes */}
              <Route path="/appearance" element={<ComingSoon />} />
              <Route path="/themes" element={<Blog />} />
              <Route path="/customise" element={<Customise />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/menu/add" element={<CreateMenu />} />
              <Route path="/menu/edit" element={<CreateMenu />} />
            </Route>
          </Route>
        </Routes>
        </Suspense>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;

function PrivateRoute() {
  const [searchparams] = useSearchParams();
  const location = useLocation();
  const secret = searchparams.get("secret");

  if (!localStorage?.getItem("first_name")) {
    localStorage.clear();
    <Navigate to={`/login`} />;
  }

  return localStorage?.getItem("token") ||
    (location?.pathname == `/create-store` && secret) ? (
    <Outlet />
  ) : (
    <Navigate to={`/login`} />
  );
}

function PublicRoute() {
  return !localStorage?.getItem("token") ? <Outlet /> : <Navigate to="" />;
}
